<template>
  <div class="col-md-12" v-if="Object.keys(delivery_challan.bearer_name).length !== 0">
    <h5>Bearer Details</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Name: </b> {{ delivery_challan.bearer_name }}<br>
            <b>Designation: </b> {{ delivery_challan.bearer_phone_no }}<br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'sender_detail',
  props: ['delivery_challan'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
