<template>
  <div v-if="Object.keys(delivery_challan).length !== 0">
    <delivery-challan-from-hub-detail :delivery_challan="delivery_challan"></delivery-challan-from-hub-detail>
    <delivery-challan-to-hub-detail v-if="delivery_challan.delivery_to" :delivery_challan="delivery_challan"></delivery-challan-to-hub-detail>
    <bearer-detail v-if="delivery_challan.bearer_name" :delivery_challan="delivery_challan"></bearer-detail>
    <delivery-challan-header-detail :delivery_challan="delivery_challan" :spinning="spinning" :delayTime="delayTime"></delivery-challan-header-detail>
    <delivery-challan-header-status :current="current" :delivery_challan="delivery_challan"></delivery-challan-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm table-bordered" id="requisition_item_list">
              <thead>
              <tr>
                <th>#</th>
                <th>Item</th>
                <th>Unit</th>
                <th>Transfer Qty</th>
                <th>Received Qty</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(delivery_challan_item, index) in delivery_challan_items" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ delivery_challan_item.challan_item }}</td>
                <td>{{ delivery_challan_item.unit }}</td>
                <td>{{ delivery_challan_item.transfer_qty }}</td>
                <td>{{ delivery_challan_item.received_qty || 'N/A' }}</td>
                <td>
                  <a-tag color="#f50" v-if="delivery_challan_item.status == '1'">Pending</a-tag>
                  <a-tag color="#87d068" v-if="delivery_challan_item.status == '2'">Full Received</a-tag>
                  <a-tag color="#E0A800" v-if="delivery_challan_item.status == '3'">Partially Received</a-tag>
                  <a-tag color="#f50" v-if="delivery_challan_item.status == '4'">Rejected</a-tag>
                </td>
                <td>
                  <a v-if="delivery_challan_item.status == '1'" href="javascript: void(0);" v-b-modal.received-full-accept-modal @click="$bvModal.show('received-full-accept-modal'), fullAccept(delivery_challan_item)" class="btn btn-sm btn-success mr-1">Accept</a>
                  <a v-if="delivery_challan_item.status == '1'"  href="javascript: void(0);" v-b-modal.received-partial-accept-modal @click="$bvModal.show('received-partial-accept-modal'), partialAccept(delivery_challan_item)" class="btn btn-sm btn-warning">Partial Accept</a>
                  <a v-if="delivery_challan_item.status == '3'" :href="delivery_challan_item.delivery_challan_item_picture" target="_blank" download>
                    <img :src="delivery_challan_item.delivery_challan_item_picture" alt="" class="rounded d-block" width="40" height="40"/>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <h5 class="text-center text-secondary" v-if="delivery_challan_items.length === 0">
              <Empty :image="simpleImage"></Empty>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12" v-if="delivery_challan.delivery_challan_picture">
            <h5>Delivery Challan Picture</h5>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="float-left text-center">
                    <a :href="delivery_challan.delivery_challan_picture_url" target="_blank" download>
                      <img :src="delivery_challan.delivery_challan_picture_url" alt="" class="rounded img-thumbnail" width="80" height="80"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="delivery_challan_item_receiver_count === 0">
      <div v-if="delivery_challan.delivery_challan_status.includes('RECEIVER_PENDING') && !delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')">
        <div class="card">
          <div class="card-body">
            <div class="mt-3 mb-3">
              <div class="form-row">
                <div class="col-md-12">
                  <label for="receiver" class="col-form-label col-form-label-sm">Receiver`s Detail <span class="custom-required">*</span></label>
                  <div class="form-row">
                    <div class="col-md-10">
                      <v-select v-model="delivery_challan_received.receiver" :class="errors.has('receiver') ? 'error-border-color' : ''" name="receiver" id="receiver" v-validate="'required'" label="name" :options="employees" placeholder="Select receiver"></v-select>
                    </div>
                    <div class="col-md-2">
                  <span>
                      <a class="btn btn-success" href="javascript: void(0);" v-b-modal.add-employee-modal @click="$bvModal.show('add-employee-modal')"><i class="fa fa-plus"/> Add Receiver</a>
                  </span>
                    </div>
                    <h6 v-if="delivery_challan.receiver" style="margin-top: 3px; margin-left: 3px">
                      <b>Sender Name:</b> {{ receiverInfo.name }} <br>
                      <b>Designation:</b> {{ receiverInfo.designation }} <br>
                      <b>Department:</b> {{ receiverInfo.department.department_name }}
                    </h6>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mt-2">
                  <a  href="javascript: void(0);" @click="challanReceived()" class="btn btn-success waves-effect waves-light"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Received All</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <employee-add v-model="employees"></employee-add>
    <full-accept :delivery_challan_item="delivery_challan_item" @getDeliveryChallanItems="getDeliveryChallanItems"></full-accept>
    <partial-accept :delivery_challan_item="delivery_challan_item" @getDeliveryChallanItems="getDeliveryChallanItems"></partial-accept>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import fullAccept from '@/views/deliveryChallan/partial/fullAccept'
import partialAccept from '@/views/deliveryChallan/partial/partialAccept'
import deliveryChallanHeaderDetail from '@/views/deliveryChallan/partial/delivery_challan_header_detail'
import deliveryChallanHeaderStatus from '@/views/deliveryChallan/partial/delivery_challan_header_status'
import deliveryChallanFromHubDetail from '@/views/deliveryChallan/partial/delivery_challan_from_hub_detail'
import deliveryChallanToHubDetail from '@/views/deliveryChallan/partial/delivery_challan_to_hub_detail'
import employeeAdd from '@/views/deliveryChallan/partial/employeeAdd'
import bearerDetail from '@/views/deliveryChallan/partial/bearer_detail'

export default {
  name: 'List',
  components: { Empty, employeeAdd, fullAccept, partialAccept, deliveryChallanHeaderDetail, deliveryChallanHeaderStatus, deliveryChallanFromHubDetail, deliveryChallanToHubDetail, bearerDetail },
  data() {
    return {
      employees: [],
      delivery_challan_item: {},
      delivery_challan_items: {},
      delivery_challan: {},
      delivery_challan_received: {
        receiver: '',
      },
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      delivery_challan_item_receiver_count: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  computed: {
    receiverInfo() {
      return this.delivery_challan_received.receiver ? this.employees.find(element => {
        if (element.id === this.delivery_challan_received.receiver.id) {
          return element
        }
        return null
      }) : ''
    },
  },
  mounted() {
    this.getCodes()
    this.getDeliveryChallanItems()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getCodes() {
      apiClient.get('/api/delivery-challan/codes')
        .then(response => {
          this.hubs = response.data.hubs
          this.employees = response.data.employees
        })
        .catch(error => {
          console.log(error)
        })
    },
    getDeliveryChallanItems() {
      const deliveryChallanId = this.$route.params.delivery_challan_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/delivery-challan/view/' + deliveryChallanId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.delivery_challan_items = data.delivery_challan_items
            this.delivery_challan = data.delivery_challan
            this.current = data.max_delivery_challan_status
            this.delivery_challan_item_receiver_count = data.delivery_challan_item_receiver_count
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    challanReceived() {
      this.delivery_challan_received.delivery_challan_id = this.delivery_challan.id
      this.$validator.validate().then(valid => {
        if (valid) {
          apiClient.post('api/hub/delivery-challan/received', this.delivery_challan_received).then(response => {
            if (!response.error) {
              if (response.data.error === true) {
                this.$notification.error({
                  message: response.data.message,
                })
              } else {
                this.$notification.success({
                  message: response.data.message,
                })
                this.getDeliveryChallanItems()
              }
            } else {
              this.$notification.error({
                message: 'Deleted Failed',
              })
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
    fullAccept(deliveryChallanItem) {
      this.delivery_challan_item = {}
      this.delivery_challan_item = deliveryChallanItem
    },
    partialAccept(deliveryChallanItem) {
      this.delivery_challan_item = {}
      this.delivery_challan_item = deliveryChallanItem
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
</style>
