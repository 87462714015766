<template>
  <div class="col-md-12" v-if="Object.keys(delivery_challan).length !== 0">
    <h5>Delivery From</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Name: </b> {{ delivery_challan.delivery_from.name }}<br>
            <b>Address: </b> {{ delivery_challan.delivery_from.address }}<br>
            <b>Phone: </b> {{ delivery_challan.delivery_from.phone }}
          </div>
          <div class="col-md-6">
            <b>Hub Employees: </b> {{ delivery_challan.delivery_from.hub_employees }}<br>
            <b>Department Working: </b>
            <template v-for="(department, index) in delivery_challan.delivery_from.departments">
                <span class="item" :key="index">
                    {{ department.department_name }}
                      <span v-if="index != Object.keys(delivery_challan.delivery_from.departments).length - 1">, </span>
                </span>
            </template><br>
            <b>Email: </b> {{ delivery_challan.delivery_from.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'delivery_challan_from_hub_detail',
  props: ['delivery_challan'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
