<template>
  <div class="col-md-12" v-if="Object.keys(delivery_challan).length !== 0">
    <div class="card">
      <div class="card-body">
        <a-steps :current="current" size="small" status="wait">
          <a-step title="Delivered" :description="delivery_challan.delivery_challan_statuses.filter(e => e.status === 'SENDER_SEND').length > 0 ? customDate(delivery_challan.delivery_challan_statuses.find(e => e.status === 'SENDER_SEND').created_at) : ''" />
          <a-step title="Received" v-if="delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED') && !delivery_challan.delivery_challan_status.includes('RECEIVER_REJECTED')"  :description="delivery_challan.delivery_challan_statuses.filter(e => e.status === 'RECEIVER_RECEIVED').length > 0 ? customDate(delivery_challan.delivery_challan_statuses.find(e => e.status === 'RECEIVER_RECEIVED').created_at) : ''" />
          <a-step class="ant-steps-item-error" title="Delivered Deleted" v-else-if="delivery_challan.delivery_challan_status.includes('SENDER_DELETED') && !delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')"  :description="delivery_challan.delivery_challan_statuses.filter(e => e.status === 'SENDER_DELETED').length > 0 ? customDate(delivery_challan.delivery_challan_statuses.find(e => e.status === 'SENDER_DELETED').created_at) : ''" >
            <a-icon slot="icon" type="close-circle" />
          </a-step>
          <a-step class="ant-steps-item-error" title="Receiver Rejected" v-else-if="delivery_challan.delivery_challan_status.includes('RECEIVER_REJECTED') && !delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')"  :description="delivery_challan.delivery_challan_statuses.filter(e => e.status === 'RECEIVER_REJECTED').length > 0 ? customDate(delivery_challan.delivery_challan_statuses.find(e => e.status === 'RECEIVER_REJECTED').created_at) : ''" >
            <a-icon slot="icon" type="close-circle" />
          </a-step>
          <a-step title="Received Pending" v-else></a-step>
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'delivery_challan_header_status',
  props: ['current', 'delivery_challan'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
}
</style>
